import {isToday} from './utils'
import axios from '@axios'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
    if (!value) return ''
    const str = value.toString()

    const arr = str.split(replacer)
    const capitalizedArray = []
    arr.forEach(word => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalizedArray.push(capitalized)
    })
    return capitalizedArray.join(' ')
}

export const recordStatusFormat = value => {
    return value === true || value === 'True' ? 'Aktif' : 'Pasif'
}

export const isDefaultFormat = value => {
    return value ? 'Evet' : 'Hayır'
}

export const recordStatusVariant = value => {
    return value === true || value === 'True' ? 'success' : 'secondary'
}

export const recordFormStatusVariant = value => {
    if (value.formStatusId === 'CORFIRMATION') {
        return 'primary';
    } else if (value.formStatusId === 'WAITING') {
        return 'warning';
    } else if (value.formStatusId === 'APPROVED') {
        return 'success';
    } else if (value.formStatusId === 'DENIED') {
        return 'danger';
    } else if (value.formStatusId === 'CANCELLED') {
        return 'danger';
    } else {
        return 'secondary'
    }
}

export const workflowStatusVariant = value => {
    if (value.workflowStatusTypeId === 'PRE_WAITING') {
        return 'secondary';
    } else if (value.workflowStatusTypeId === 'WAITING') {
        return 'warning';
    } else if (value.workflowStatusTypeId === 'APPROVED') {
        return 'success';
    } else if (value.workflowStatusTypeId === 'DENIED') {
        return 'danger';
    } else if (value.workflowStatusTypeId === 'CANCELLED') {
        return 'danger';
    } else {
        return 'secondary'
    }
}

export const recordTaskStatusVariant = value => {
    if (value.taskStatusId === 'WAITING') {
        return 'warning';
    } else if (value.taskStatusId === 'PROCESSING') {
        return 'primary';
    } else if (value.taskStatusId === 'ANSWERED') {
        return 'primary';
    } else if (value.taskStatusId === 'CLOSED') {
        return 'success';
    } else if (value.taskStatusId === 'CANCELLED') {
        return 'danger';
    } else {
        return 'info'
    }
}

export const recordTaskPriorityStatusVariant = value => {
    if (value.taskPriorityStatusId === 'URGENT') {
        return 'danger';
    } else if (value.taskPriorityStatusId === 'HIGH') {
        return 'danger';
    } else if (value.taskPriorityStatusId === 'MEDIUM') {
        return 'primary';
    } else {
        return 'secondary'
    }
}

export const recordPriorityStatusVariant = value => {
    if (value.priorityStatusId === 'URGENT') {
        return 'danger';
    } else if (value.priorityStatusId === 'HIGH') {
        return 'danger';
    } else if (value.priorityStatusId === 'MEDIUM') {
        return 'primary';
    } else {
        return 'secondary'
    }
}

export const recordIdeaStatusVariant = value => {
    if (value.ideaStatusId === 'NEW') {
        return 'warning';
    } else if (value.ideaStatusId === 'EVALUATING') {
        return 'primary';
    } else if (value.ideaStatusId === 'APPROVED') {
        return 'success';
    } else if (value.ideaStatusId === 'DENIED') {
        return 'danger';
    } else {
        return 'danger'
    }
}

export const statusOptions = [
    {label: 'Pasif', value: false},
    {label: 'Aktif', value: true},
]

export const yesNoOptions = [
    {label: 'Hayır', value: false},
    {label: 'Evet', value: true},
]

export const formStatusFilterOptions = [
    {label: 'Tümü', value: null},
    {label: 'Onay Bekliyor', value: 'WAITING'},
    {label: 'Onayda', value: 'CORFIRMATION'},
    {label: 'Onaylandı', value: 'APPROVED'},
    {label: 'Reddedildi', value: 'DENIED'},
    {label: 'İptal Edildi', value: 'CANCELLED'},
]

export const workFlowStatusFilterOptions = [
    {label: 'Onay Bekliyor', value: 'WAITING'},
    {label: 'Sıra Bekliyor', value: 'PRE_WAITING'},
    {label: 'Onaylandı', value: 'APPROVED'},
    {label: 'Reddedildi', value: 'DENIED'},
    {label: 'İptal Edildi', value: 'CANCELLED'},
]

export const perPageOptions = [10, 25, 50, 100]

export const getApiFile = (filePath) => {
    if (filePath !== null && filePath !== '' && filePath !== undefined) {
        return axios.defaults.baseURL + '/file?path=' + filePath;
    } else {
        return '';
    }
}

export const avatarText = value => {
    if (!value) return ''
    if (isNaN(value)) {
        const nameArray = value.split(' ')
        return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    } else {
        return ''
    }
}

export const randomColor = () => {
    var colors = [
        "#adcc03",
        "#6bddab",
        "#f4a609",
        "#ffde0a",
        "#00d1ca",
        "#b159ff",
        "#7deb00",
        "#8dbafe",
        "#ffcc99",
        "#d4aaff",
        "#ff6d6d",
        "#93c655",
        "#efc851",
        "#ffa54f",
        "#f18eba",
        "#90ee90",
        "#87cefa",
        "#8deeee",
        "#f08080",
        "#f4a460",
        "#00e07c",
        "#fc9336",
        "#4d97ff",
        "#fe983e",
        "#f393f5",
        "#95bcf5",
        "#adeb1b",
        "#64f2de",
        "#fad4c5",
        "#f481e9",
        "#12ce5c"
    ];

    var randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
}) => {
    if (!value) return value
    return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}

export const formatDateTime = (value, locale = 'tr-TR', formatting = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}) => {
    if (!value) return value
    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value))
}

export const formatTime = (value, locale = 'tr-TR', formatting = {
    hour: 'numeric',
    minute: 'numeric',
}) => {
    if (!value) return value
    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: 'short', day: 'numeric'}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: 'numeric', minute: 'numeric'}
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
